.column-czevid {
    cursor: pointer;
}


.dynamic-delete-button {
    cursor: pointer;
    position: relative;
    font-size: 1rem;
    color: #999;
    transition: all .3s;
}
.dynamic-delete-button:hover {
    color: #777;
}
.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}

.card-container > .ant-tabs.ant-tabs-card > .ant-tabs-content {
    margin-top: -16px;
}

.card-container > .ant-tabs.ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
}

.card-container > .ant-tabs.ant-tabs-card > .ant-tabs-bar {
    border-color: #fff;
}

.card-container > .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
}

.card-container > .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
}

.copyable {
    font-weight: bold;
}

.copyable:hover {
    color: #1890ff;
}

.clickable-icon {
    opacity: 0.6;
    cursor: pointer;
}

.clickable-icon:hover {
    opacity: 1;
}

.blue {
    color: #1890ff;
}

.fade-enter {
    height: 0;
}

.fade-enter.fade-enter-active {
    height: auto;
    transition: height 2000ms ease-in;
}

.fade-leave {
    height: auto;
}

.fade-leave.fade-leave-active {
    height: 0;
    transition: height 2000ms ease-in;
}

.czev-catalogue-search-form .ant-form-item {
    display: flex;
}

.czev-catalogue-search-form .ant-form-item-label {
    width: 90px;
}

.czev-catalogue-search-form .ant-form-item-control-wrapper {
    flex: 1;
}


.uploaded-files_list .ant-list-item-content {
    position: relative;
}
