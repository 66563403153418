.star-detail-wrapper {
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
}

.star-detail {
    margin-right: 12px;
}

.star-detail input {
    width: 90px;
}

.legendItem.deselected {
    opacity: 0.5;
    text-decoration: line-through;
}

.tab-wrapper {
    display: flex;
}

.tab-wrapper > .tab-item {
    text-align: center;
    padding: 16px 8px;
    flex-grow: 1;
    flex-basis: 100%;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    position: relative;
}

.tab-wrapper > .tab-item:hover {
    background: #eee;
}

.tab-wrapper > .tab-item.selected {
    border-bottom-color: #0038a8;
    border-width: 2px;
}

.tab-wrapper > .tab-item.selected::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    border-bottom: 1px solid #0038a8;
}

.mono {
    font-family: "Courier New", Courier, monospace;
}

.minima-list {
    display: flex;
    flex-direction: column;
}

.minima-list > .tab-wrapper {
    flex: 0 0 auto;
}

.minima-list .panel-body {
    overflow: auto;
    flex: 1 auto;
}

.minima-list textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    min-width: 100%;
    min-height: 200px;
}

.loadable {
    position: relative;
}

.loadable::after {
    content: '';
    display: block;
    position: absolute;
    top: -16px;
    left: -8px;
    width: 0;
    height: 4px;
    background-color: #1890ff;
}

.loadable.loading::after {
    width: calc(100% + 16px);
    transition: width 1s;
}
