body {
    background-color: #f0f2f5 !important;
}

.stars-detail-wrapper {
    flex: 1;
    overflow: auto;
}

.panel {
    /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);*/
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    border-radius: 2px;
    background-color: white;
    overflow: hidden;
    border: 1px solid #e8e8e8;
}

.panel.selectable:hover:not(.selected) {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.panel.selectable.selected {
    background-color: #dde9ff;
}

.panel-header.selectable:hover, .panel-header.selected {
    background-color: #1890ff;
    color: white;
}

.selectable {
    cursor: pointer;
}

.panel > .panel-header {
    padding: 8px;
    border-bottom: 1px solid #eeeeee;
    /*border-top-left-radius: 8px;*/
    /*border-top-right-radius: 8px;*/
}

.panel > .panel-body {
    padding: 8px;
    /*border-bottom-left-radius: 8px;*/
    /*border-bottom-right-radius: 8px;*/
}

.space-out {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2px;
}

.space-out > b {
    margin-right: 3px;
}

.text-error {
    color: #ba160c;
}

.oc-gate-app {
    display: flex;
}

.ant-menu-item.ant-menu-submenu {
    border-color: #001529;
    border-bottom: 0;
    top: 0;
    margin-top: 0;
}

.ant-menu-item.ant-menu-submenu a {
    color: rgba(255, 255, 255, 0.65);
}

.ant-menu-item.ant-menu-submenu.ant-menu-submenu-selected, .ant-menu-item.ant-menu-submenu.ant-menu-submenu-active {
    background-color: #1890ff;
}

.ant-menu-item.ant-menu-submenu.ant-menu-submenu-selected *, .ant-menu-item.ant-menu-submenu.ant-menu-submenu-active * {
    color: #fff;
}

.popover-minima-graph .ant-popover-inner-content {
    padding: 0;
}

.table-small-nomargin .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
}


.brightness-item-wrapper {
    margin-bottom: 3rem;
}

.brightness-item-wrapper:not(:last-child) .ant-form-item:last-child > .ant-form-item-control-wrapper:after {
    content: '';
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    background-color: #ccc;
    margin-top: 1.5rem;
}
